<template>
  <!-- ===============================================-->
  <!--    Main Content-->
  <!-- ===============================================-->
  <main class="main" 
    style="margin-top: 55px; margin-bottom: 0" id="top">
    
    <app-navbar />

    <!-- ============================================-->
    <!-- <section> begin ============================-->
    <section class="py-0 overflow-hidden" id="banner">

      <div 
        class="bg-holder overlay" 
        style="background-image:url(/img/generic/bg-6.jpg);background-position: center bottom;">
      </div>
      <!--/.bg-holder-->

      <div class="container mobile">
        <div class="row justify-content-center align-items-top pt-6 pt-lg-10 pb-lg-10 pb-xl-0"
          style="max-height: 95vh">
          <div  
            class="col-md-11 col-lg-8 col-xl-4 pb-7 pb-xl-9 text-center text-xl-left">
            <router-link v-if="false"
              class="btn btn-outline-light mb-4 fs--0 font-weight-light" to="/">
              <i class="fas fa-play-circle mr-1"></i>
              ВИДЕО ТУР
            </router-link>
              <h2 class="text-white font-weight-light" slyle="display: inline-block;">
                <span 
                  class="typed-text font-weight-bold text-center">
                  <vue-typed-js 
                    class="typed-center"
                    :strings="['Просто и понятно!', 'Быстро и легко!', 'Качественно!', 'Профессионально!']"
                    :startDelay="1000"
                    :typeSpeed="100"
                    :loop="true">
                    <span class="typing"></span>
                  </vue-typed-js>
                </span>
              </h2>
              <p class="lead text-1000 mobile-d-none" >
                Создание профессиональных <br> бизнес-планов никогда не было таким простым 
                и легким!
              </p>
            
            <router-link 
              class="btn btn-success btn-lg mt-4 fs-0 py-2 text-uppercase" 
              to="/registration">
              Создать бизнес-план!
              <span 
                class="fas fa-play ml-2" 
                data-fa-transform="shrink-6 down-1 right-5">
              </span>
            </router-link>
          </div>
          <div class="col-xl-7 offset-xl-1 align-self-end">
            <router-link class="img-landing-banner" to="/registration">
              <img 
                class="img-fluid" 
                src="../assets/img/generic/dashboard5.png" 
                alt="" />
            </router-link>
          </div>
        </div>
      </div>
      <!-- end of .container-->

    </section>
    <!-- <section> close ============================-->
    <!-- ============================================-->

    <!-- ============================================-->
    <!-- <section> begin ============================-->
    <section class="py-3 bg-light shadow-sm">

      <div class="container">
        <div class="row justify-content-center align-items-center">
          <div class="col-3 col-sm-auto my-1 my-sm-3">
            <img 
              class="landing-cta-img mr-2" 
              height="60" 
              src="../assets/img/logos/fin_univer.png" 
              alt="Vevo!" />
          </div>
          <div class="col-3 col-sm-auto my-1 my-sm-3">
            <img 
              class="landing-cta-img" 
              height="60" 
              src="../assets/img/logos/mos_ent_dep.png" 
              alt="" />
          </div>
          <div class="col-3 col-sm-auto my-1 my-sm-3">
            <img 
              class="landing-cta-img" 
              height="30" 
              src="../assets/img/logos/banki_ru.png" 
              alt="" />
          </div>
          <div class="col-3 col-sm-auto my-1">
            <img 
              class="landing-cta-img" 
              height="100" 
              src="../assets/img/logos/myfi24_ru.svg" 
              alt="" />
            </div>
          <div class="col-3 col-sm-auto my-1 my-sm-3">
            <img 
              class="landing-cta-img" 
              height="60" 
              src="../assets/img/logos/stratman_ru.png" 
              alt="" />
          </div>
        </div>
      </div>
      <!-- end of .container-->

    </section>
    <!-- <section> close ============================-->
    <!-- ============================================-->

    <!-- ============================================-->
    <!-- <section> begin ============================-->
    <section class="py-7">
      <div class="container mobile">
        <div class="row justify-content-center text-center">
          <div class="col-lg-10 col-xl-10">
            <h1 class="fs-2 fs-sm-4 fs-md-5 mb-3">
              Планируй, получай финансирование <br> и развивай бизнес
            </h1>
            <p class="lead align-bottom">
              <img 
                class="mr-1" 
                src="@/assets/img/illustrations/bplan-logo.png" style="filter: contrast(120%);" alt="" width="25" />
              <strong>ТВОЙ</strong><small class="text-800 text-uppercase"> бизнес-план</small> - поможет продумать каждый аспект Вашего бизнеса увеличив Ваши <strong>шансы на успех</strong>  в несколько раз!
            </p>
          </div>
        </div>

        <div class="row align-items-center justify-content-center mt-4">
          <div class="card mb-3" style="max-width: 90%;">
            <div class="row no-gutters">
              <div class="col-md-4 p-2">
                <img src="../assets/img/illustrations/sample-plans.svg" class="card-img mt-2" alt="...">
              </div>
              <div class="col-md-8">
                <div class="card-body">
                  <h5 class="card-title">Используйте информацию от более чем 100 готовых бизнес планов</h5>
                  <p class="card-text">Просмотрите сотни примеров бизнес-планов, которые охватывают все мыслимые отрасли промышленности. Это поможет вам представить, как должен выглядеть ваш готовый план.</p>
                </div>
                <div class="card-footer" style="position: absolute; bottom: 5px;">
                  <button class="btn btn-outline-success btn-sm text-uppercase font-weight-light">
                    Ознакомься с категориями <i class="fas fa-angle-double-right ml-2"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="card mb-3" style="max-width: 90%;">
            <div class="row no-gutters">
              <div class="col-md-4 p-2">
                <img src="../assets/img/illustrations/plan-guide.svg" class="card-img mt-2" alt="...">
              </div>
              <div class="col-md-8">
                <div class="card-body">
                  <h5 class="card-title">Воспользуйтесь продуманным руководством по написанию планов</h5>
                  <p class="card-text">Следуйте пошаговым инструкциям, которые подскажут Вам, что нужно писать в каждом разделе вашего бизнес-плана. Подробные разъяснения ключевых терминов и видеоуроки упростят процесс планирования.</p>
                </div>
                <div class="card-footer" style="position: absolute; bottom: 5px;">
                  <button class="btn btn-outline-success btn-sm text-uppercase font-weight-light">
                    Посмотри как это работает <i class="fas fa-angle-double-right ml-2"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="card mb-3" style="max-width: 90%;">
            <div class="row no-gutters">
              <div class="col-md-4 p-2">
                <img src="../assets/img/illustrations/plan-pitch.svg" class="card-img mt-2" alt="...">
              </div>
              <div class="col-md-8">
                <div class="card-body">
                  <h5 class="card-title">Организуй и продвигай свои идеи</h5>
                  <p class="card-text">Отточите и протестируйте свои идеи, создав одностраничный питч. Используйте его, чтобы легко объяснить инвесторам ваши бизнес-возможности.</p>
                </div>
                <div class="card-footer" style="position: absolute; bottom: 5px;">
                  <button class="btn btn-outline-success btn-sm text-uppercase font-weight-light">
                    Посмотри примеры питчей <i class="fas fa-angle-double-right ml-2"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="card mb-3" style="max-width: 90%;">
            <div class="row no-gutters">
              <div class="col-md-4 p-2">
                <img src="../assets/img/illustrations/financial-plan.svg" class="card-img mt-2" alt="...">
              </div>
              <div class="col-md-8">
                <div class="card-body">
                  <h5 class="card-title">Постройте точные финансовые прогнозы</h5>
                  <p class="card-text">Больше никаких возни с электронными таблицами. <strong>ТВОЙ</strong><small class="text-800 text-uppercase"> бизнес-план</small> проведет вас через простой процесс построения профессиональных финансовых прогнозов и отчетов твоего бизнес-плана.</p>
                </div>
                <div class="card-footer" style="position: absolute; bottom: 5px;">
                  <button class="btn btn-outline-success btn-sm text-uppercase font-weight-light">
                    Убедись как это легко <i class="fas fa-angle-double-right ml-2"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="card mb-3" style="max-width: 90%;">
            <div class="row no-gutters">
              <div class="col-md-4 p-2">
                <img src="../assets/img/illustrations/banks-format-plan.svg" class="card-img mt-2" alt="...">
              </div>
              <div class="col-md-8">
                <div class="card-body">
                  <h5 class="card-title">Используйте формат, подходящий для банков и инвесторов</h5>
                  <p class="card-text">Твой бизнес-план будет соответствовать утвержденному международному формату, разработанному предпринимателями, которые привлекли инвестиции на многие миллионы долларов.</p>
                </div>
                <div class="card-footer" style="position: absolute; bottom: 5px;">
                  <button class="btn btn-outline-success btn-sm text-uppercase font-weight-light">
                    Подробнее <i class="fas fa-angle-double-right ml-2"></i>
                  </button>
                </div>
              </div>
            </div>
          </div> 
        </div>

        <div class="row text-center " >
          <div class="col-md-1 col-sm-none"></div>
          <div class="col-md-5 col-sm-12 text-center mt-4 mb-2">
            <button class="btn btn-outline-secondary btn-block text-uppercase font-weight-light">Ознакомиться с функциями планирования</button>
          </div>
          <div class="col-md-5 col-sm-12 text-center mt-4">
            <button class="btn btn-outline-secondary btn-block text-uppercase font-weight-light">Ознакомиться с функциями финасовой модели</button>
          </div>
          <div class="col-md-1 col-sm-none"></div>
        </div>

      </div>
      <!-- end of .container-->
    </section>
    <!-- <section> close ============================-->
    <!-- ============================================-->

    <!-- ============================================-->
    <!-- <section> begin ============================-->
    <section v-if="false" 
      class="bg-light text-center pt-7">
      <div class="container mobile">
        <div class="row">
          <div class="col">
            <h1 class="fs-2 fs-sm-4 fs-md-5">
              Более чем 50'000 пользователей, <br> малого и среднего бизнеса, остались довольны!
              
            </h1>
            <p class="lead mb-5">
              Доказано, что компании, которые планируют и отслеживают свои планы, растут на 30% быстрее, чем те, которые этого не делают! Наши пользователи являются живым доказательством. Вне зависимости от отрасли <strong>ТВОЙ</strong><small class="text-800 text-uppercase"> бизнес-план</small> станет прочным фундаментом для начала собственного бизнеса.
            </p> 
            <router-link class="btn btn-outline-primary text-uppercase font-weight-light" to="/">
              Прочитать успешные истории пользователей!
            </router-link>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-lg-4">
            <div class="hoverbox rounded-soft text-center">
              <img class="img-fluid" src="/img/generic/capitol-cider-house.jpg" alt="">
              <div class="hoverbox-content bg-light-dark-transparent p-3 align-items-center">
                <div>
                  <p class="lead text-white font-weight-light">
                    Есть замечательное выражение: <br> 
                    "Планы бесполезны, но планирование необходимо!" <br> 
                    Я думаю, что это очень верно. В современном мире все меняется очень быстро!
                  </p>
                  <a class="btn btn-light btn-sm mt-1 text-uppercase font-weight-light" href="#!">
                    Прочитать 
                    <i class="fas fa-angle-double-right ml-2"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="hoverbox rounded-soft text-center">
              <img class="img-fluid" src="/img/generic/web-ninja.jpg" alt="">
              <div class="hoverbox-content bg-light-dark-transparent p-3 align-items-center">
                <div>
                  <p class="lead text-white font-weight-light">
                    [Интерактивная панель - <strong>ТВОЙ</strong><small class="text-800 text-uppercase"> бизнес-план</small>] позволяет детализировать информацию до нужного уровня - это значительно упрощает понимание финансовой информации для "не-бухгалтеров"</p>
                  <a class="btn btn-light btn-sm mt-1 text-uppercase font-weight-light" href="#">
                    Прочитать 
                    <i class="fas fa-angle-double-right ml-2"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="hoverbox rounded-soft text-center">
              <img class="img-fluid" src="/img/generic/trots-dogs.jpg" alt="">
              <div class="hoverbox-content bg-light-dark-transparent p-3 align-items-center">
                <div>
                  <p class="lead text-white font-weight-light">
                    С помощью сервиса <strong>ТВОЙ</strong><small class="text-800 text-uppercase"> бизнес-план</small> я легко прошел через все необходимые этапы создания бизнес-плана, и это было легко и довольно-таки познавательно, сейчас я понимаю, что превращение идеи в бизнес-план - всего лишь пошаговый процесс!"
                  </p>
                  <a class="btn btn-light btn-sm mt-1 text-uppercase font-weight-light" href="#!">
                    Прочитать 
                    <i class="fas fa-angle-double-right ml-2"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end of .container-->
    </section>
    <!-- <section> close ============================-->
    <!-- ============================================-->

    <!-- ============================================-->
    <!-- <section> begin ============================-->
    <section>

      <div 
        class="bg-holder overlay" 
        style="background-image:url(/img/generic/bg-2.jpg);background-position: center top;">
      </div>
      <!--/.bg-holder-->

      <div class="container mobile">
        <div class="row justify-content-center text-center">
          <div class="col-lg-10">
            <p class="lead fs-2 fs-sm-3 mb-5 text-white">
              Присоединяйтесь к более чем 50'000 успешных частных предпринимателей и владельцев бизнеса. <br> 
              Достигайте финансовый успех вместе с нами!
            </p>
            <router-link v-if="false"
              class="btn btn-outline-secondary mb-4 fs--0 font-weight-light" to="/">
              <i class="fas fa-play-circle mr-1"></i>
              ВИДЕО ТУР
            </router-link>
            <router-link  to="/registration"
              class="btn btn-success mb-4 ml-5 fs--0 font-weight-normal">
              <i class="fas fa-key mr-1"></i>
              Регистрация
            </router-link>
          </div>
        </div>
      </div>
      <!-- end of .container-->

    </section>
    <!-- <section> close ============================-->
    <!-- ============================================-->

    <!-- ============================================-->
    <!-- <section> begin ============================-->

    <app-footer-menu />
      <!-- end of .container-->

    <!-- <section> close ============================-->
    <!-- ============================================-->
   

  </main>
  <!-- ===============================================-->
  <!--    End of Main Content-->
  <!-- ===============================================-->
</template>

<script>
import Navbar from './Navbar';
import FooterMenu from './FooterMenu';


export default {
  metaInfo() {
    return {
      title: 'Добро пожаловать!'
    }
  },
  data: () => ({
  }),
  components: {
    appNavbar: Navbar,
    appFooterMenu: FooterMenu,
  }
}
</script>

<style lang="scss" scoped>

.bg-light-dark-transparent {
  background-color: #293b53cb;
}

.lead {
  background: rgba(0,0,0, 0.4);
  border-radius: 3px;
  padding: 3px 10px;
}

@media (max-width: 400px) {
  .mobile-d-none {
    display: none !important; 
  }

  .img-landing-banner {
    margin-bottom: -20rem !important;
  }

  .mobile {
    padding-left: 0.2rem;
    padding-right: 0.2rem; 
  }
}

@media (max-width: 899px) {
  li {
    display: grid;
  }

}

@media (min-width: 990px) {
  li {
    margin-right: 5px;
  }
}

@media (max-width: 1199px) {
  .typed-center {
    align-items: center; 
    justify-content: center; 
    box-sizing: border-box;
  }
}

</style>
